/*----------------------------------------
    Login Page CSS
------------------------------------------*/
.login-bg
{
    /*background-image: url('/app-assets/images/gallery/flat-bg.jpg');*/
    background-color: #f5f5f5;
    background-repeat: no-repeat;
    background-size: cover;
}

#login-page
{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;

    height: 100vh;

    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
            align-items: center;
}

#login-page .card-panel.border-radius-6.login-card
{
    margin-left: 0 !important;
}
